export function MakeAccountExternalTransferController ($scope, resourceFactory, location, routeParams) {
  $scope.params = {
    fromAccountId: routeParams.accountId,
    inOrOut: routeParams?.inOrOut || 'outgoing'
  };
  const accountType = routeParams.accountType || '';

  $scope.bypassTransferLimits = false;
  $scope.loading = false;
  const promiseCallbackHandler = (callback) => {
    return () => {
      $scope.loading = false;
      if (callback) callback();
    };
  };

  $scope.countryOptions = [];
  $scope.stateOptions = [];
  let stateProvinceIdOptions = [];
  $scope.showDefaultCountry = false;
  let isCountryCodeAppended = false;
  $scope.transferParty = {
    outgoing: {
      originator: 'debtor',
      benificiary: 'creditor'
    },
    incoming: {
      originator: 'creditor',
      benificiary: 'debtor'
    }
  };

  if (accountType === 'fromsavings') $scope.params.fromAccountType = 2;
  else if (accountType === 'fromloans') $scope.params.fromAccountType = 1;
  else $scope.params.fromAccountType = 0;

  $scope.formData = {
    address: ['', ''],
    agentAddress: ['', ''],
    correspondent: { address: ['', ''] },
    accountType: 'CHECKING',
    traceNumbers: []
  };

  function sortedCountry (_countries) {
    return _countries.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  resourceFactory.clientaddressFields.get(function (data) {
    $scope.addressTypes = data.addressTypeIdOptions;
    $scope.countryOptions = sortedCountry(data.countryIdOptions);
    stateProvinceIdOptions = data.stateProvinceIdOptions;
  });

  $scope.onCountryChange = function (countryId) {
    const unitedStates = $scope.countryOptions.find(country => country.name === 'United States');
    if (countryId === unitedStates.id) {
      $scope.postalValidationRequired = true;
    }
    $scope.stateOptions = sortedStateByCountry();
  };

  $scope.handleCountryDropDown = function () {
    if (['WIRE', 'ACH'].includes($scope.formData.paymentType)) {
      $scope.defaultCountry = [$scope.countryOptions.find(country => country.name === 'United States')];
      $scope.formData.countryId = $scope.defaultCountry[0].id;
      $scope.formData.originatorCountry = $scope.defaultCountry[0].identifier;
    } else {
      $scope.formData.countryId = '';
    }
    $scope.stateOptions = sortedStateByCountry();
  };

  function sortedStateByCountry () {
    const states = stateProvinceIdOptions.filter(function (_option) {
      return _option.parentId === $scope.formData.countryId;
    });

    return states.sort(function (a, b) {
      return (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0;
    });
  }

  $scope.updateTransferType = () => {
    if ($scope.params.inOrOut === 'incoming' && $scope.formData.selectedTransferType === 'DEBIT') {
      $scope.transferParty.incoming = {
        originator: 'debtor',
        benificiary: 'creditor'
      };
    } else {
      $scope.transferParty.incoming = {
        originator: 'creditor',
        benificiary: 'debtor'
      };
    }
  };

  function getSubmitData () {
    const transferType = $scope.params.inOrOut === 'outgoing' ? 'CREDIT' : $scope.formData.selectedTransferType;
    const data = {
      type: transferType,
      dateFormat: $scope.df,
      paymentType: $scope.formData.paymentType,
      amount: $scope.formData.amount,
      [$scope.transferParty[$scope.params.inOrOut].originator]: {
        name: $scope.formData.originatorName,
        identifier: `id:${routeParams.accountId}`,
        accountType: $scope.formData.accountType
      },
      [$scope.transferParty[$scope.params.inOrOut].benificiary]: {
        agent: {
          identifier: $scope.formData.agentRoutingCode
        },
        name: $scope.formData.benificiaryName,
        identifier: `${$scope.formData.paymentType}://${$scope.formData.agentRoutingCode}/${$scope.formData.accountNumber}`,
        accountType: $scope.formData.accountType
      }
    };

    if ($scope.params.inOrOut === 'outgoing' && ['ACH'].includes($scope.formData.paymentType)) {
      data.payment_rail_meta_data = { bypass_transfer_limits: $scope.bypassTransferLimits };
    }
    let cityStr;
    let selectedState;
    let trimmedZipCode;

    if ($scope.formData.city) {
      cityStr = $scope.formData.city.substring(0, 23);
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].city = $scope.formData.city;
    }

    if ($scope.formData.stateProvinceId) {
      selectedState = stateProvinceIdOptions.find(stateOption => $scope.formData.stateProvinceId === stateOption.id);
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].stateOrProvince = selectedState.name;
    }

    if ($scope.formData.postalCode) {
      trimmedZipCode = $scope.formData.postalCode.substring(0, 5);
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].postalCode = $scope.formData.postalCode;
    }

    if ($scope.formData.countryId) {
      const selectedCountry = $scope.countryOptions.find(countryOption => $scope.formData.countryId === countryOption.id);
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].country = selectedCountry.identifier;
    }

    if (['ACH'].includes($scope.formData.paymentType) && $scope.formData.originatorCountry) {
      data[$scope.transferParty[$scope.params.inOrOut].originator].country = $scope.formData.originatorCountry;
    }

    if (['WIRE', 'ACH'].includes($scope.formData.paymentType)) {
      const addr = [cityStr, selectedState.identifier, trimmedZipCode, data[$scope.transferParty[$scope.params.inOrOut].benificiary].country];
      $scope.formData.address.splice(2, 1);
      const addressLine3 = addr.join(',');
      $scope.formData.address.push(addressLine3);
    } else {
      if (isCountryCodeAppended) {
        $scope.formData.address[2] = $scope.formData.address[2].slice(0, -3);
      } else {
        isCountryCodeAppended = true;
      }
      const actualAddr3 = $scope.formData.address[2];
      $scope.formData.address.splice(2, 1);
      const concatCountryCode = ','.concat(data[$scope.transferParty[$scope.params.inOrOut].benificiary].country);
      $scope.formData.address.push(actualAddr3 + concatCountryCode);
    }

    if ($scope.formData.address) {
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].address = $scope.formData.address;
    }

    if ($scope.formData.agentName) {
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].agent.name = $scope.formData.agentName;
    }
    if ($scope.formData.creditorFfc) {
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].forFurtherCredit = $scope.formData.creditorFfc;
    }
    if ($scope.formData.agentAddress) {
      data[$scope.transferParty[$scope.params.inOrOut].benificiary].agent.address = $scope.formData.agentAddress;
    }
    if ($scope.formData.reference) {
      data.reference = $scope.formData.reference.trim().split('\n');
      data.statementDescription = $scope.formData.reference.trim();
    }

    data.correspondent = { identifier: $scope.formData.correspondent.routingCode, name: $scope.formData.correspondent.name, address: $scope.formData.correspondent.address };

    // modify traceNumbers to accepted format
    const traceNumbers = $scope.formData.traceNumbers.map((traceNumber) => {
      if (!traceNumber.key || !traceNumber.value) {
        return undefined;
      }

      return [traceNumber.key, traceNumber.value];
    }).filter(Boolean);

    data.traceNumbers = Object.fromEntries(traceNumbers);

    return data;
  }

  resourceFactory.accountTransfersTemplateResource.get($scope.params, function (data) {
    $scope.transfer = data;
  });

  resourceFactory.paymentTypeResource.getAll(function (dataPaymentTypes) {
    $scope.paymentTypes = dataPaymentTypes.filter(function (dataPaymentType) {
      if (process.env.VUE_APP_SUBLEDGER !== 'evolve') {
        return ['ACH'].includes(dataPaymentType.name) && dataPaymentType.isSystemDefined;
      }

      const neededSystemDefinePaymentType = ['ACH', 'WIRE', 'SWIFT'].includes(dataPaymentType.name) && dataPaymentType.isSystemDefined;
      const extraPaymentType = ['SAMEDAYACH'].includes(dataPaymentType.name);

      return neededSystemDefinePaymentType || extraPaymentType;
    });

    // Custom names for payment types
    const paymentTypeLabels = {
      WIRE: 'Domestic Wire',
      SWIFT: 'International Wire'
    };

    // Check if we have custom name for the payment type or not.
    $scope.paymentTypes.forEach(paymentType => {
      paymentType.label = paymentTypeLabels[paymentType.name] || paymentType.name;
    });
  });

  $scope.submit = function () {
    const isWireOrSwift = ['WIRE', 'SWIFT'].includes($scope.formData.paymentType);
    if (!isWireOrSwift) {
      $scope.formData.creditorFfc = '';
      $scope.formData.correspondent = {};
    }

    const submitData = getSubmitData();
    const command = isWireOrSwift ? { command: 'create' } : {};
    $scope.loading = true;
    resourceFactory.transferResource.save(command, submitData, promiseCallbackHandler(function () {
      location.path(`/viewsavingaccount/${routeParams.accountId}`);
    }), promiseCallbackHandler());
  };

  $scope.cancel = function () {
    window.history.back();
  };

  $scope.addTraceNumber = function () {
    $scope.formData.traceNumbers.push({});
  };

  $scope.removeTraceNumber = function (index) {
    $scope.formData.traceNumbers.splice(index, 1);
  };
}

MakeAccountExternalTransferController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
