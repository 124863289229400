<template>
  <div>
    <button
      class="btn btn-primary m-1"
      @click="showModal"
    >
      {{ $t('label.button.logs') }}
    </button>
    <base-modal
      ref="logsModal"
      modal-size="lg"
      show-button-close
      hide-footer
      scrollable
    >
      <template #header>
        {{ $t('label.heading.logs') }}
      </template>
      <template #body>
        <pre>{{ displayData }}</pre>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import i18n from '@/plugins/i18n';
import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';
import { ref } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
});

const displayData = ref(i18n.t('label.heading.noLogs'));
const logsModal = ref(null);
const showModal = () => {
  logsModal.value.show();
  getLogs();
};

const getLogs = async () => {
  try {
    const { data } = await apiService.clients.externalKycRequestLogs(props.data.id);
    displayData.value = data.logs?.length ? data.logs : i18n.t('label.heading.noLogs');
  } catch (e) {
    $notify.error(e);
  }
};

</script>
