<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.heading.riskRating') }}
    </template>
    <template #actions>
      <button
        v-if="!isEditing"
        class="btn btn-primary"
        @click="edit"
      >
        {{ $t('label.button.edit') }}
      </button>
    </template>
    <form-wrapper
      v-if="!isLoading"
      class="col-sm-5 mt-4"
      @submit="submit"
    >
      <input-select
        v-model="formData.riskRating"
        :options="riskRatingItem"
        :label="$t('label.input.riskRating')"
        not-clearable
        text-field="value"
        value-field="id"
        :display-mode="!isEditing"
      />
      <input-date-picker
        v-model="formData.dueDiligenceDate"
        format="YYYY-MM-DD"
        :label="$t('label.input.riseRatingDate')"
        :display-mode="!isEditing"
      />
      <div
        v-if="isEditing"
        class="offset-md-4 mt-3 d-flex"
      >
        <button
          class="btn btn-default mx-1"
          @click.prevent="cancel"
        >
          {{ $t('label.button.cancel') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary d-inline-flex align-items-center justify-content-center mx-1"
        >
          <span
            v-if="isSubmitting"
            class="spinner-border spinner-border-sm me-2"
            aria-hidden="true"
          />
          <span>{{ $t('label.button.submit') }}</span>
        </button>
      </div>
    </form-wrapper>
    <div
      v-else
      class="col-sm-5 mt-4"
    >
      {{ $t('label.loading') }}...
    </div>
  </main-content-layout>
</template>
<script setup>
import apiService from '@/services/apiService';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { set, get } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { ref, onMounted, reactive } from 'vue';
import { InputSelect, InputDatePicker, FormWrapper } from '@/components/shared/form-input';
import { getAngularRootScope } from '@/helper/angularRootScope';
import { $notify } from '@/plugins/prototypes';

const riskRatingItem = ref([]);
const initialFormData = ref({});
const isEditing = ref(false);
const isLoading = ref(false);
const isSubmitting = ref(false);

const formData = reactive({
  riskRating: null,
  dueDiligenceDate: null
});

const props = defineProps({
  clientId: {
    type: String,
    required: true
  }
});

onMounted(async () => getRiskRatings());

async function getRiskRatings () {
  set(isLoading, true);
  try {
    const { data: { riskRatings } } = await apiService.riskRating.template(props.clientId);
    const { data: { dueDiligenceDate, riskRating } } = await apiService.riskRating.get(props.clientId);

    set(riskRatingItem, riskRatings);
    formData.riskRating = riskRating.id;
    formData.dueDiligenceDate = dateStringToFormat(dueDiligenceDate, 'YYYY-MM-DD');
  } catch (error) {
    $notify.error(error);
  } finally {
    set(isLoading, false);
  }
}

function edit () {
  Object.assign(get(initialFormData), { ...formData });
  set(isEditing, true);
}

function cancel () {
  Object.assign(formData, { ...get(initialFormData) });
  set(isEditing, false);
}

async function submit () {
  set(isSubmitting, true);
  try {
    await apiService.riskRating.update(props.clientId, formData);
    getRiskRatings();
  } catch (error) {
    const $rootScope = getAngularRootScope();
    $rootScope.setApiValidationError(error);
  } finally {
    set(isSubmitting, false);
  }
}
</script>
