export function ViewEmployeeAccountController (scope, routeParams, resourceFactory, paginatorService, location, $uibModal, route, dateFilter, $sce) {
  scope.report = false;
  scope.hidePentahoReport = true;
  scope.showActiveCharges = true;
  scope.formData = {};
  scope.date = {};
  scope.staffData = {};
  scope.fieldOfficers = [];
  scope.savingaccountdetails = [];
  scope.transactionsPerPage = 15;
  scope.holdFunds = [];
  scope.holdFunsPerPage = 15;
  scope.totalHoldFunds = 0;
  scope.totalTransactions = 0;
  scope.savingsAccountTransactions = [];
  scope.savingsAccountPendingTransactions = [];
  scope.totalPendingTransactions = '';
  scope.accountBlockButtonText = '';
  scope.blockPermission = '';
  scope.subAccountId = routeParams.subAccountId;
  scope.clientId = routeParams.clientId;
  scope.primaryAccountId = null;

  scope.savingsAccountNickname = '';
  scope.stopPayments = [];
  scope.defaultDecimalPlace = 2;
  scope.showInterestAccruals = false;
  scope.floatingRates = [];
  scope.floatingRatesPerPage = 15;
  scope.totalFloatingRates = 0;
  scope.accountTypeRoute = location.search().accountType;
  scope.parentClientId = routeParams.parentClientId;

  resourceFactory.clientResource.get({ clientId: scope.clientId }, function (clientdata) {
    scope.clientName = clientdata.displayName;
  });
  scope.isDebit = function (savingsTransactionType) {
    return savingsTransactionType.isDebit === true;
  };

  scope.routeTo = function (transactionId, transactionValue) {
    if (transactionValue !== 'Funds Deallocation' && transactionValue !== 'Funds Allocation') {
      return location.path(`savingsaccounts/${scope.primaryAccountId}/subsidiaryaccounts/${routeParams.subAccountId}/transactions/${transactionId}`);
    }
  };

  /** *
             * we are using orderBy(https://docs.angularjs.org/api/ng/filter/orderBy) filter to sort fields in ui
             * api returns dates in array format[yyyy, mm, dd], converting the array of dates to date object
             * @param dateFieldName
             */
  scope.convertDateArrayToObject = function (dateFieldName) {
    for (const i in scope.savingaccountdetails.transactions) {
      scope.savingaccountdetails.transactions[i][dateFieldName] = scope.savingaccountdetails.transactions[i].date;
    }
  };

  scope.clickEvent = function (eventName, accountId) {
    eventName = eventName || '';
    switch (eventName) {
      case 'fundTransfer':
        location.path(`/clients/${scope.parentClientId}/associated-client/${scope.clientId}/saving-accounts/${scope.subAccountId}/fund-transfer`).search({ primaryAccountId: scope.primaryAccountId });
        break;
      case 'approve':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/approve`);
        break;
      case 'reject':
        location.path(`/savingaccount/${accountId}/reject`);
        break;
      case 'undoapproval':
        location.path(`/savingaccount/${accountId}/undoapproval`);
        break;
      case 'activate':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/activate`);
        break;
      case 'blockAccount':
        $uibModal.open({
          templateUrl: 'block.html',
          controller ($scope, $uibModalInstance) {
            $scope.reason = '';
            $scope.confirm = function () {
              resourceFactory.savingsResource.save({ accountId, command: 'block' }, { reason: $scope.reason }, function () {
                $scope.cancel();
                route.reload();
              });
            };
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }
        });
        break;
      case 'unBlockAccount':
        resourceFactory.savingsResource.save({ accountId, command: 'unblock' }, {}, function () {
          route.reload();
        });
        break;
      case 'blockCardAccount':
        resourceFactory.savingsResource.save({ accountId, command: 'blockcard' }, {}, function () {
          route.reload();
        });
        break;
      case 'openSubAccount':
        resourceFactory.manageEmployeeAccounts.create({ primaryAccountId: scope.primaryAccountId }, {
          clientId: scope.savingaccountdetails.clientId, locale: 'en', dateFormat: 'dd-MM-yy'
        }, function () {
          route.reload();
        });
        break;
      case 'unblockCardAccount':
        resourceFactory.savingsResource.save({ accountId, command: 'unblockcard' }, {}, function () {
          route.reload();
        });
        break;
      case 'close':
        location.path(`/savingaccount/${accountId}/close`).search({ primaryAccountId: scope.primaryAccountId });
        break;
    }
  };
  scope.filterData = {
    startDate: '',
    endDate: '',
    transactionType: '',
    transactionSubType: '',
    reference: ''
  };
  scope.transactionTypes = [
    { id: 1, value: 'Deposit' },
    { id: 2, value: 'Withdrawal' },
    { id: 3, value: 'Interest posting' },
    { id: 4, value: 'Withdrawal fee' },
    { id: 5, value: 'Annual fee' },
    { id: 6, value: 'Waive Charge' },
    { id: 7, value: 'Pay Charge' },
    { id: 8, value: 'Dividend Payout' },
    { id: 12, value: 'Transfer initiated' },
    { id: 13, value: 'Transfer approved' },
    { id: 14, value: 'Transfer Withdrawn' },
    { id: 15, value: 'Transfer Rejected' },
    { id: 16, value: 'writtenoff' },
    { id: 17, value: 'Overdraft Interest' },
    { id: 18, value: 'Withhold Tax' },
    { id: 19, value: 'Escheat' },
    { id: 20, value: 'Amount on hold' },
    { id: 21, value: 'Release Amount' },
    { id: 22, value: 'Interest Payable Accrued' },
    { id: 23, value: 'Overdraft Interest Accrued' }
  ];
  scope.subTransactionTypes = [
    { id: 1, value: 'Card Transaction' },
    { id: 2, value: 'Settlement Return Credit' },
    { id: 3, value: 'Loan Disbursement' },
    { id: 4, value: 'Loan Repayment' },
    { id: 5, value: 'Card Authorise Payment' },
    { id: 6, value: 'Domestic ATM Withdrawal Fee' },
    { id: 7, value: 'International ATM Withdrawal Fee' },
    { id: 8, value: 'International Transaction Fee' },
    { id: 11, value: 'Merchant Credit' },
    { id: 12, value: 'Merchant Credit Reversal' }
  ];
  scope.getResultsPage = function (pageNumber) {
    this.formData.locale = scope.optlang.code;
    const dateFormat = 'yyyy-MM-dd';
    scope.filterData.startDate = dateFilter(scope.filterData.startDate, dateFormat);
    scope.filterData.endDate = dateFilter(scope.filterData.endDate, dateFormat);
    resourceFactory.employeeAccountTrxnsResource.getAllTransactions({
      primaryAccountId: scope.primaryAccountId,
      subsidiaryAccountId: scope.subAccountId,
      offset: ((pageNumber - 1) * scope.transactionsPerPage),
      limit: scope.transactionsPerPage
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.savingsAccountTransactions = data.pageItems;
    });
  };

  scope.searchTransactions = function () {
    this.formData.locale = scope.optlang.code;
    const dateFormat = 'yyyy-MM-dd';
    scope.filterData.startDate = dateFilter(scope.filterData.startDate, dateFormat);
    scope.filterData.endDate = dateFilter(scope.filterData.endDate, dateFormat);
    const tempOffset = 0;
    resourceFactory.employeeAccountTrxnsResource.getAllTransactions({
      primaryAccountId: scope.primaryAccountId,
      subsidiaryAccountId: scope.subAccountId,
      offset: tempOffset,
      limit: scope.transactionsPerPage,
      subTransactionType: scope.filterData.transactionSubType,
      transactionType: scope.filterData.transactionType,
      startDate: scope.filterData.startDate,
      endDate: scope.filterData.endDate,
      reference: scope.filterData.reference
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.savingsAccountTransactions = data.pageItems;
    });
  };

  scope.initPage = function () {
    resourceFactory.employeeAccountTrxnsResource.getAllTransactions({
      primaryAccountId: scope.primaryAccountId,
      subsidiaryAccountId: scope.subAccountId,
      limit: scope.transactionsPerPage
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.savingsAccountTransactions = data.pageItems;
    });
  };

  scope.buttons = { options: [] };
  resourceFactory.savingsResource.get({ accountId: scope.subAccountId, associations: 'charges' }, function (data) {
    scope.savingaccountdetails = data;
    scope.formatDecimalPlace = data.currency.decimalPlaces;
    if (data.subStatus.id === 400) {
      scope.accountBlockButtonText = 'button.unBlockAccount';
      scope.blockPermission = 'UNBLOCK_SAVINGSACCOUNT';
    } else {
      scope.accountBlockButtonText = 'button.blockAccount';
      scope.blockPermission = 'BLOCK_SAVINGSACCOUNT';
    }
    scope.savingsAccountNickname = data.savingsAccountNickname;
    scope.primaryAccountId = data.parentAccount.id;

    scope.initPage();

    scope.convertDateArrayToObject('date');
    if (scope.savingaccountdetails.groupId) {
      resourceFactory.groupResource.get({ groupId: scope.savingaccountdetails.groupId }, function (data) {
        scope.groupLevel = data.groupLevel;
      });
    }

    scope.staffData.staffId = data.staffId;
    scope.date.toDate = new Date();
    scope.date.fromDate = new Date(data.timeline.activatedOnDate);

    scope.status = data.status.value;
    if (scope.status === 'Submitted and pending approval' || scope.status === 'Active' || scope.status === 'Approved') {
      scope.choice = true;
    }
    scope.chargeAction = data.status.value === 'Submitted and pending approval';
    scope.chargePayAction = data.status.value === 'Active';
    if (scope.savingaccountdetails.charges) {
      scope.charges = scope.savingaccountdetails.charges;
      if (data.charges) {
        for (const i in scope.charges) {
          if (scope.charges[i].name === 'Annual fee - INR') {
            scope.buttons.options.push({
              name: 'button.applyAnnualFees',
              taskPermissionName: 'APPLYANNUALFEE_SAVINGSACCOUNT'
            });
            scope.annualChargeId = scope.charges[i].id;
          }
          // covert object date into correct format
          const day = scope.charges[i].feeOnMonthDay?.day;
          const affix = findDateAffix(day);
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Monthly Fee') {
            scope.charges[i].feeOnMonthDay = day + affix;
          }
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Annual Fee') {
            scope.charges[i].feeOnMonthDay = `${day + affix} ${dateFilter(new Date(Object.values(scope.charges[i].feeOnMonthDay)), 'MMMM')}`;
          }
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Weekly Fee') {
            scope.charges[i].feeOnMonthDay = '';
          }
        }
      }
      scope.chargeTableShow = true;
    } else {
      scope.chargeTableShow = false;
    }

    if (data.status.value === 'Submitted and pending approval') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.approve',
            icon: 'fas fa-check',
            taskPermissionName: 'APPROVE_SAVINGSACCOUNT'
          }
        ],
        options: [
          {
            name: 'button.reject',
            taskPermissionName: 'REJECT_SAVINGSACCOUNT'
          }
        ]
      };
    }

    if (data.status.value === 'Approved') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.undoapproval',
            icon: 'fas fa-undo',
            taskPermissionName: 'APPROVALUNDO_SAVINGSACCOUNT'
          },
          {
            name: 'button.activate',
            icon: 'fas fa-check',
            taskPermissionName: 'ACTIVATE_SAVINGSACCOUNT'
          }
        ]
      };
    }

    if (data.status.value === 'Active') {
      if (data.subStatus.value.toLowerCase() !== 'block' && scope.accountTypeRoute !== 'prepaid') {
        scope.buttons = {
          singlebuttons: [
            {
              name: 'button.fundTransfer',
              icon: 'fas fa-arrow-up',
              taskPermissionName: 'DEPOSIT_SAVINGSACCOUNT'
            }
          ],
          options: []
        };
      }

      if (data.cardRestricted) {
        scope.buttons.options.push(
          {
            name: 'button.unblockCardAccount',
            taskPermissionName: 'UNBLOCK_CARDACCOUNT'
          }
        );
      } else {
        scope.buttons.options.push(
          {
            name: 'button.blockCardAccount',
            taskPermissionName: 'BLOCK_CARDACCOUNT'
          }
        );
      }
    }

    if (data.status.value === 'Closed') {
      scope.buttons = {
        options: [
          {
            name: 'button.statementsAndDocuments',
            taskPermissionName: 'READ_MEDIA'
          }
        ]
      };
    }

    if (data.status.code === 'savingsAccountStatusType.invalid') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.openSubAccount',
            icon: 'fas fa-arrow-up'
          }
        ],
        options: []
      };
    }

    if (data.annualFee && data.annualFee.feeOnMonthDay) {
      let annualdueDate = [];
      annualdueDate = Object.values(data.annualFee.feeOnMonthDay);
      annualdueDate.push(new Date().getFullYear());
      scope.annualdueDate = new Date(annualdueDate);
    }

    if (data.status.value !== 'Closed') {
      scope.buttons.options?.push(
        {
          name: scope.accountBlockButtonText,
          taskPermissionName: scope.blockPermission
        }
      );
      // add close account for last
      if (scope.accountTypeRoute !== 'prepaid') {
        scope.buttons.options?.push(
          {
            name: 'button.close',
            taskPermissionName: 'CLOSE_SAVINGSACCOUNT'
          }
        );
      }
    }

    resourceFactory.standingInstructionTemplateResource.get({ fromClientId: scope.savingaccountdetails.clientId, fromAccountType: 2, fromAccountId: scope.subAccountId }, function (response) {
      scope.standinginstruction = response;
      scope.searchTransaction();
    });

    scope.getPendingTransactions();
    scope.getHoldFunds();
  });

  scope.getPendingTransactions = function (pageNumber = 1) {
    resourceFactory.getPendingTransactions.get({
      savingsId: scope.subAccountId,
      offset: ((pageNumber - 1) * scope.transactionsPerPage),
      limit: scope.transactionsPerPage
    }, function (response) {
      scope.savingsAccountPendingTransactions = response.pageItems?.map(item => ({
        transferId: item?.id,
        createdAt: item.createdAt,
        amount: item.amount,
        description: item.transfer?.reference?.join('\n') || item.cardAuthorization?.merchant?.description || item.reference,
        type: item.type
      }));
      scope.totalPendingTransactions = response.totalFilteredRecords;
    });
  };

  scope.getHoldFunds = function (pageNumber = 1) {
    resourceFactory.savingsAmountResource.get({
      savingsAccountId: scope.subAccountId,
      offset: ((pageNumber - 1) * scope.holdFunsPerPage),
      limit: scope.holdFunsPerPage
    }, function (response) {
      scope.holdFunds = response.pageItems?.map(holdFund => ({
        id: holdFund.id,
        holdAuthorizeTransactionId: holdFund.holdAuthorizeTransactionId,
        reference: holdFund.externalAuthId,
        amount: holdFund.amount,
        status: holdFund.status,
        releasedAt: holdFund.releasedAt,
        expiryDate: holdFund.expiryDate,
        createdAt: holdFund.createdAt,
        clearedAmount: holdFund.clearedAmount,
        pendingAmount: holdFund.pendingAmount
      }));
      scope.totalHoldFunds = response.totalFilteredRecords;
    });
  };

  const findDateAffix = function (day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = scope.optlang.code;
    params.fromAccountId = scope.subAccountId;
    params.fromAccountType = 2;
    params.clientId = scope.savingaccountdetails.clientId;
    params.clientName = scope.savingaccountdetails.clientName;
    params.dateFormat = scope.df;

    resourceFactory.standingInstructionResource.search(params, callback);
  };

  scope.searchTransaction = function () {
    scope.displayResults = true;
    scope.instructions = paginatorService.paginate(fetchFunction, 14);
    scope.isCollapsed = false;
  };

  scope.getNotesData = function () {
    resourceFactory.savingAccountNotes.query({ accountId: scope.subAccountId }, function (data) {
      scope.savingAccountNotes = data;
    });
  };

  scope.viewSavingDetails = function () {
    scope.report = false;
    scope.hidePentahoReport = true;
    scope.viewReport = false;
  };

  scope.viewPrintDetails = function () {
    // scope.printbtn = true;
    scope.report = true;
    scope.viewTransactionReport = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');

    const reportParams = {
      'output-type': 'PDF',
      locale: scope.optlang.code,
      R_startDate: scope.startDate,
      R_endDate: scope.endDate,
      R_savingsAccountId: scope.savingaccountdetails.accountNo
    };

    resourceFactory.clientSavingTransactionsReportResource.get(reportParams, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // allow untrusted urls for iframe http://docs.angularjs.org/error/$sce/insecurl
      scope.viewReportDetails = $sce.trustAsResourceUrl(fileURL);
    });
  };

  scope.exportToCsv = function () {
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');
    const reportParams = {
      'output-type': 'CSV',
      locale: scope.optlang.code,
      R_startDate: scope.startDate,
      R_endDate: scope.endDate,
      R_savingsAccountId: scope.savingaccountdetails.accountNo
    };
    resourceFactory.clientSavingTransactionsCSVReportResource.get(reportParams, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(file, 'download');
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(file);
          link.setAttribute('href', url);
          link.setAttribute('download', `${scope.savingaccountdetails.accountNo}_savings_transactions`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  };

  scope.transactionSort = {
    column: 'id',
    descending: true
  };

  scope.changeTransactionSort = function (column) {
    const sort = scope.transactionSort;
    if (sort.column === column) {
      sort.descending = !sort.descending;
    } else {
      sort.column = column;
      sort.descending = true;
    }
  };

  scope.saveNote = function () {
    resourceFactory.noteResource.save({ resourceType: 'savings', resourceId: scope.subAccountId }, { note: scope.formData.note }, function () {
      scope.getNotesData();
      scope.formData.note = undefined;
    });
  };

  const EditNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.editData = {};
    $scope.editData.editNote = items.sNote;
    $scope.editNote = function () {
      resourceFactory.noteResource.put({ resourceType: 'savings', resourceId: scope.subAccountId, noteId: items.sId }, { note: this.editData.editNote }, function () {
        scope.savingAccountNotes[items.index].note = $scope.editData.editNote;
        $uibModalInstance.close();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.showEditNote = function (index) {
    const sId = scope.savingAccountNotes[index].id;
    const sNote = scope.savingAccountNotes[index].note;
    $uibModal.open({
      templateUrl: 'editNote.html',
      controller: EditNoteCtrl,
      resolve: {
        items () {
          return {
            sId,
            sNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  scope.showDeleteNote = function (index) {
    const sId = scope.savingAccountNotes[index].id;
    const sNote = scope.savingAccountNotes[index].note;
    $uibModal.open({
      templateUrl: 'deleteNote.html',
      controller: DeleteNoteCtrl,
      resolve: {
        items () {
          return {
            sId,
            sNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  const DeleteNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.note = items.sNote;
    $scope.deleteNote = function () {
      resourceFactory.noteResource.delete({ resourceType: 'savings', resourceId: scope.subAccountId, noteId: items.sId }, '', function () {
        $uibModalInstance.close();
        scope.savingAccountNotes.splice(items.index, 1);
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.checkStatus = function () {
    if (scope.status === 'Active' || scope.status === 'Closed' || scope.status === 'Transfer in progress' ||
      scope.status === 'Transfer on hold' || scope.status === 'Premature Closed' || scope.status === 'Matured') {
      return true;
    }
    return false;
  };
}

ViewEmployeeAccountController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'paginatorService', '$location', '$uibModal', '$route', 'dateFilter', '$sce'];
